import { defineStore } from "pinia";

const useCurrentBusinessIdStore = defineStore({
  id: "business",
  state: () => ({
    businessId: null,
  }),
  actions: {
    setBusinessId(id) {
      this.businessId = id;
    },
  },
});

export default useCurrentBusinessIdStore;
